import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import { Typography, Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

const Policy = ({ pageContext }) => {
  const [t, i18n] = useTranslation()
  const classes = useStyles()
  const { pLang } = pageContext

  const [aboutUsData, setAboutUsData] = useState()

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `/${i18n.language}/cookies-policy`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `cookies-policy`, `/${i18n.language}/cookies-policy`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/cookies-policy`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("pages.cookiesPolicy"),
    },
  ]

  return (
    <>
      <SEO title={t("pages.cookiesPolicy")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("pages.cookiesPolicy")} />
      <Container className={classes.mainContainer}>
        <Typography variant="h6" component="p">
          This Cookies Policy explains how Gwthani use cookies and similar technologies. Whenever you use Gwthani’s
          website, apps, products, advertising services or other technologies (“Services”), or visit a website, app or
          service which uses our Services, information may be collected through the use of cookies and similar
          technologies. Gwthani is committed to protecting the personal information collected when you use our Services.
        </Typography>
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          This Cookies Policy provides the following information for users:
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are cookies and similar technologies?
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are the different types of cookies?
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are cookies used for?
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          How does Future use cookies?
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          How can users manage or opt out of cookies?
        </Typography>
        <Typography component="p" variant="body1" className={classes.boldText}>
          Contact details and where to find further information
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are cookies and similar technologies?
        </Typography>
        <Typography component="p" variant="body1">
          A cookie is a small data file, often including a unique identifier, which is sent to your computer, mobile
          phone or tablet device (referred to in this policy as a “device”) by the web server so the website can
          remember some information about your browsing activity on the website. The cookie will collect information
          relating to your use of our Services, information about your device, e.g. the device’s IP address and browser
          type, broad location and, if you arrived at our site via a link from third party site, the URL of the linking
          page. If you have registered for any Services or you are a subscriber, it may also collect your name and email
          address, which may be transferred to data processors for registered user or subscriber verification purposes.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          Similar technologies known as “local storage” may also be used in our Services as an alternative to cookies.
          This is technology which is similar to cookies and performs very similar functions, such as caching data to
          enable us to improve our Services, allowing you to register for our Services, tracking activity to enable us
          and our advertisers to advertise to you in accordance with this policy and counting the number of people who
          view adverts on our Services. Where we refer to “cookies” in this policy, we mean cookies or similar
          technologies.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          Cookies record information about your online preferences and help us to tailor our Services to your interests.
          Information provided by cookies can help us to analyse your use of our Services and help us to provide you
          with a better user experience.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          In addition to cookies, our Services may also use web beacons, clear GIFs, page tags and web bugs. These are
          all types of technology implemented by website or third party ad servers to allow them to analyse your website
          use and help improve your experience of the Services.
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are the different types of cookies?
        </Typography>
        <Typography component="p" variant="body1">
          <b>Session cookies</b> are only stored for the duration of your visit to a website and are deleted from your
          device when you close your browser;
        </Typography>
        <Typography component="p" variant="body1">
          <b>Persistent cookies</b> are saved on your device for a fixed period of time after the browser has closed and
          are used where we (or a third party) need to identify you for a later browsing session. The fixed period of
          time is typically 30-90 days for targeting or behavioural advertising cookies and may be up to 26 months for
          analytical performance and measurement cookies;
        </Typography>
        <Typography component="p" variant="body1">
          <b>First party cookies</b> are set by the website you are visiting; and
        </Typography>
        <Typography component="p" variant="body1">
          <b>Third party cookies</b> are cookies used within our Services which are set by other organisations. These
          include cookies from external analytics services which help us to understand the use of our site, or by
          advertisers so that they can track the effectiveness of their advertisements.
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          What are the different types of cookies?
        </Typography>
        <Typography component="p" variant="body1">
          <b>1. Functional Cookies</b>
          <br />
          Functional cookies are essential to the running of our Services. They are used to remember your preferences on
          our website and to provide enhanced, more personal features. The information collected by these cookies is
          usually anonymised, so we cannot identify you personally. Functional cookies do not track your internet usage
          or gather information which could be used for selling advertising, but they do help with serving advertising.
          These cookies are usually session cookies which will expire when you close your browsing session.
        </Typography>
        <Typography component="p" variant="body1">
          <b>2. Essential or ‘Strictly Necessary’ Cookies</b> <br /> These cookies are essential for the running of our
          Services. Without these cookies, parts of our website would not function. These cookies do not track where you
          have been on the internet and do remember preferences beyond your current visit and do not gather information
          about you that could be used for marketing purposes. These cookies are usually session cookies which will
          expire when you close your browsing session.
        </Typography>
        <Typography component="p" variant="body1">
          <b> 3. Analytical Performance and Measurement Cookies</b> <br />
          Analytical performance cookies are used to monitor the performance of our Services, for example, to determine
          the number of pageviews and the number of unique users a website has. Web analytics services may be designed
          and operated by third parties. The information provided by these cookies allows us to analyse patterns of user
          behaviour and we use that information to enhance user experience or identify areas of the website which may
          require maintenance. The information is anonymous (i.e. it cannot be used to identify you and does not contain
          personal information such as your name and email address) and it is only used for statistical purposes
        </Typography>
        <Typography component="p" variant="body1">
          <b>4. Targeting or ‘Behavioural Advertising’ Cookies</b> <br />
          These cookies, which may be placed on your device by us or our trusted third party service providers, remember
          that you have visited a website and use that information to provide you with content or advertising which is
          tailored to your interests. This is often called online behavioural advertising (OBA) and is done by grouping
          together shared interests based upon web browsing history. Your web browsing history can be used to infer
          things about you (e.g. your age, gender etc.), and this information may also be used to make advertising on
          website more relevant to you. Without these cookies, content and advertisements you encounter may be less
          relevant to you and your interests.
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          How can users manage or opt out of cookies?
        </Typography>
        <Typography component="p" variant="body1">
          Gwthani, together with our trusted partners, uses cookies in combination with other information we
          maintain for a number of purposes, including the following:
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          <b>1. Essential and Functional Cookies</b>
          <br />
          We use these cookies to enable certain online functionality including:
          <br />
          access your information so we can provide you with customised content and experiences, or remember the last
          page you visited on the Services;
          <br />
          identify returning users, registrants and subscribers and allow them to be presented with a personalised
          version of the site;
          <br />
          eliminating the need for returning users to re-enter their login details;
          <br />
          commenting on our site;
          <br />
          operating a shopping trolley on various of our Services
          <br />
          maintain your settings and authenticate your identity while you are logged in to the Services;
          <br />
          to support security measures and to assist in identifying possible fraudulent or abusive activities.
          <br />
          If you object to the use of essential and functional cookies please scroll down to: How can users manage or
          opt out of cookies?
          <br />
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          <b>2. Analytical Performance and Measurement Cookies</b>
          <br />
          We use these cookies to measure users’ behaviour to better develop our Services. By using analytics services
          provided by third parties such as Google Analytics and ComScore, we can analyse and measure which pages are
          viewed and how long for and which links are followed, and we can use this information to provide more content
          which is of interest. We also use this analysis to report on our performance and to sell advertising.
          <br />
          If you object to the use of analytical performance and measurement cookies please see here: How can users
          manage or opt out of cookies?
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          <b>3. Targeting and Behavioural Advertising Cookies</b>
          <br />
          We use these cookies to:
          <br />
          manage online advertising and revenue share arrangements. Our approved advertising partners use cookies
          together with web beacons to provide advertising to you and to enable us to manage our relationship with those
          advertisers by, for example, tracking how many unique users have seen a particular advertisement or followed a
          link in an advertisement;
          <br />
          manage eCommerce activities via affiliate links and associated revenue share arrangements;
          <br />
          measure general user behaviour across our site and third party site to build a profile based on users browsing
          patterns so that we and third parties can target advertising to users that will be more relevant to users’
          interests.
          <br />
          to create profiles that trusted third parties can buy to allow them to better target their advertising with
          more relevant content; and
          <br />
          keep track of the number of users who saw a particular ad or visited a particular page of our website, analyse
          the effectiveness of our ads, and provide auditing, research and reporting for advertisers.
          <br />
          The trusted partners we work with in relation to targeting and behavioural advertising include third-party ad
          servers, ad agencies, technology vendors, providers of advertisement content, research firms and other
          companies that help us provide more effective advertising and offer you a more personalised experience. As
          your browser, app or device communicates with the third party’s servers, these companies can collect
          information, including your IP address, page header information, browser or device information, just as if you
          had requested their web page or used their apps directly.
          <br />
          We cannot control nor do we have access to any cookies placed on your computer by third party advertisers and
          sponsors.
          <br />
          If you object to the use of targeting and behavioural advertising cookies please scroll down to: How can users
          manage or opt out of cookies?
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          <b>4. Other Third Party Cookies</b>
          <br />
          You may notice on some pages of our website that cookies have been set that are not related to Gwthani.
          When you visit a page with content embedded from, for example, YouTube or Facebook, these third party service
          providers may set their own cookies on your device. Gwthani does not control the use of these third party
          cookies and cannot access them due to the way that cookies work, as cookies can only be accessed by the party
          who originally set them. Please check the third party website for more information about these cookies.
          <br />
          Some of our website use Adobe Flash Player to deliver video and game content to users. Adobe utilise their own
          cookies, which are not manageable through your browser settings but are used by the Flash Player for similar
          purposes, such as storing preferences or tracking users. Flash Cookies work in a different way to web browser
          cookies; rather than having individual cookies for particular jobs, a website is restricted to storing all
          data in one cookie. You can control how much data, if any, may be stored in that cookie but you cannot choose
          what type of information is allowed to be stored. You can manage which website can store information in Flash
          cookies on your device via the website storage settings panel on the Adobe website.
          <br />
          We also include web beacons in our emails to track the success of our marketing campaigns. This means that if
          you open an email from us we can see which of the pages of our Website you have visited. Our web beacons do
          not store additional information on your device but, by communicating with our cookies on your device, they
          can tell us when you have opened our email.
          <br />
          If you object to the use of web beacons please scroll down to: How can users manage or opt out of cookies?
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1" className={classes.boldText}>
          How can users manage or opt out of cookies?
        </Typography>
        <Typography component="p" variant="body1">
          Some people find the idea of a website storing information on their device a little intrusive, in particular
          when the information is stored and used by a third party. For example, you may object to being served
          advertising which is targeted to your interests based on your browsing history. If you would prefer to opt out
          of cookies, it is possible to control cookies by following the steps below, however you should be aware that
          you might lose some features and functionality of the website if you do so.
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          Cookies, including those which have already been set, can be deleted from your hard drive. You can also change
          the preferences/settings in your web browser to control cookies. Some internet browsers have a ‘Do Not Track
          or ‘DNT’ setting; this sends a signal to website asking them not to track your browsing. The following links
          may be helpful:
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          Cookie setting in Firefox
        </Typography>
        <Typography component="p" variant="body1">
          Cookie setting in Safari
        </Typography>
        <Typography component="p" variant="body1">
          Cookie setting in Internet Explorer
        </Typography>
        <Typography component="p" variant="body1">
          Cookie setting in Chrome
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          In some cases, you can choose to accept cookies from the primary site, but block them from third parties. In
          others, you can block cookies from specific advertisers, or clear out all cookies. Deleting or blocking
          cookies may reduce functionality of the site. To learn more about how to reject cookies, visit
          http://www.allaboutcookies.org/ or go to the help menu within your internet browser. If you experience any
          problems having deleted cookies, you should contact the supplier of your web browser.
          <br />
          For more information about third party cookies generated by advertisers please visit
          www.youronlinechoices.com.
          <br />
          Please be aware that these are third party website and Gwthani does not accept any liability for the
          instructions given on these site.
        </Typography>
        <Typography component="p" variant="body1">
          Opting out of Targeting and Behavioural Advertising Cookies:
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          You can find out how to decline targeting and behavioural advertising cookies by visiting:
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          http://optout.networkadvertising.org/?c=1#!/
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          http://www.aboutads.info
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          http://www.youronlinechoices.eu
        </Typography>
        <br />
        <br />
        <Typography component="p" variant="body1">
          <b>Web Beacons</b>
        </Typography>
        <br />
        <Typography component="p" variant="body1">
          You can normally render web beacons and other tracking technologies ineffective by switching off cookies in
          your browser. There are also browser add-ons and extensions that specifically block web beacons. If you object
          to web beacons in emails, we recommend that you follow the instructions for deleting existing cookies and
          disabling future cookies above. We will still know how many of our emails are opened and we will automatically
          receive your IP address, a unique identifier of your device or other access device; but we will not identify
          you as an individual.
        </Typography>
        <Typography component="p" variant="body1">
          Further information and contact details:
        </Typography>

        <Typography component="p" variant="body1">
          Please contact us by email: <a href="mailto:info@gwthani.com">info@gwthani.com</a>
        </Typography>
        <Typography component="p" variant="body1">
          For more information about cookies please visit <a href="http://www.allaboutcookies.org/" target="_blank">http://www.allaboutcookies.org/</a>
        </Typography>
      </Container>
    </>
  )
}
export default Policy

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  boldText: {
    fontWeight: "600",
  },
}))

const content = {}

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document
